<template>
  <dimmer :active="loading">
    <div class="container">
      <div class="d-flex align-items-center justify-content-between mb-5">
        <div class="d-flex align-items-center gap-8">
          <router-link
            to="/themes"
            class="btn btn-secondary">
            <i class="fe fe-chevron-left"></i> Back
          </router-link>
          <h2 class="card-title mb-0">Create and manage theme</h2>
        </div>
        <button
          v-if="can([uiPermissions.THEMES_CREATE, uiPermissions.THEMES_UPDATE])"
          data-cy="btn-save-theme"
          class="btn btn-primary"
          :disabled="submitting"
          @click.prevent="handleSaveTheme">
          Save theme
        </button>
      </div>
      <form
        v-disable-all="!can(uiPermissions.THEMES_UPDATE)"
        ref="form"
        class="validation"
        novalidate
        @submit.prevent="submit">
        <div class="row">
          <div class="col-sm-8">
            <div class="row">
              <div class="col">
                <div data-test="card-theme" class="card">
                  <div class="card-header">
                    <h3 class="card-title">Theme Info</h3>
                  </div>
                  <div class="card-body">
                    <div class="form-group" data-test="name-input">
                      <label class="col-form-label font-weight-semibold">Theme name <span class="text-danger">*</span></label>
                      <input
                        v-model="item.name"
                        placeholder="Enter theme name"
                        type="text"
                        class="form-control"
                        required
                      />
                      <div class="text-right mt-1" :class="{'char-limit': isNameLimit}">
                        <span
                          v-if="isNameLimit"
                          v-b-tooltip="'The theme name might be too lengthy for the website to display.'"
                          class="fe fe-info"/><small>{{(item.name || '').length}}/16</small>
                      </div>
                    </div>

                    <div class="row">
                      <div class="form-group col-md-6" data-test="main-color-input">
                        <label class="col-form-label font-weight-semibold">Theme main color</label>
                        <div class="input-group input-group-flat">
                          <input
                            v-model="item.main_color"
                            placeholder="#FFFFFF"
                            type="text"
                            class="form-control"
                          />
                          <span class="input-group-text">
                            <i :style="{color: '#c0c0c0', background: item.main_color}" class="fe fe-circle rounded-circle"></i>
                          </span>
                        </div>
                      </div>
                      <div class="form-group col-md-6" data-test="secondary-color-input">
                        <label class="col-form-label font-weight-semibold">Theme secondary color</label>
                        <div class="input-group input-group-flat">
                          <input
                            v-model="item.secondary_color"
                            placeholder="#000000"
                            type="text"
                            class="form-control"
                          />
                          <span class="input-group-text">
                            <i :style="{color: '#c0c0c0', background: item.secondary_color}" class="fe fe-circle rounded-circle"></i>
                          </span>
                        </div>
                      </div>
                    </div>

                    <div class="form-group" data-test="description-input">
                      <label class="col-form-label font-weight-semibold">[Customer] Theme long description</label>
                      <textarea
                        v-model="item.description"
                        placeholder="Explain what theme is about (to be shown when customer clicks 'Learn more')"
                        rows="5"
                        class="form-control"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col">
                <div data-test="card-theme-assets" class="card">
                  <div class="card-header">
                    <h3 class="card-title">Upload assets</h3>
                  </div>
                  <div class="card-body">
                    <div style="overflow-x: auto;">
                      <div class="form-group">
                        <label class="col-form-label font-weight-semibold">Mobile banner (.PNG)</label>
                        <image-input
                          v-model="item.mobile_banner"
                          :tags="['mobile theme banner']"
                          :thumbnail-options="{height: 130, width: 340, crop: 'fill'}"
                          class="banner-input mobile-banner-input"></image-input>
                      </div>
                      <div class="form-group">
                        <label class="col-form-label font-weight-semibold">Desktop banner (.PNG)</label>
                        <image-input
                          v-model="item.desktop_banner"
                          :tags="['desktop theme banner']"
                          :thumbnail-options="{height: 60, width: 672, crop: 'fill'}"
                          class="banner-input desktop-banner-input"></image-input>
                      </div>

                      <div class="col-form-label font-weight-semibold">Desktop decoration</div>
                      <div class="d-flex gap-12">
                        <div class="form-group">
                          <image-input
                            v-model="item.desktop_left_decoration"
                            :tags="['desktop theme decoration']"
                            :thumbnail-options="{height: 120, width: 200, crop: 'fill'}"
                            class="banner-input desktop-decoration-input"></image-input>
                          <label class="col-form-label">Left corner</label>
                        </div>
                        <div class="form-group">
                          <image-input
                            v-model="item.desktop_right_decoration"
                            :tags="['desktop theme decoration']"
                            :thumbnail-options="{height: 120, width: 200, crop: 'fill'}"
                            class="banner-input desktop-decoration-input"></image-input>
                          <label class="col-form-label">Right corner</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="row">
              <div class="col">
                <div data-test="card-theme-recipes" class="card">
                  <div class="card-header">
                    <h3 class="card-title">Recipes <template v-if="item.recipes.length">({{item.recipes.length}})</template></h3>
                  </div>
                  <div  v-if="!item.recipes.length" class="card-body">
                    <div class="empty-recipes">
                      Recipes linked to this theme will be shown here
                    </div>
                  </div>
                  <div
                    v-else
                    class="table-responsive"
                    style="max-height: 25rem; overflow-y: scroll;">
                    <table class="table text-nowrap card-table">
                      <tbody>
                        <tr v-for="recipe in item.recipes" :key="recipe.id">
                          <td class="text-left">
                            <div class="list-item">
                              <img
                                class="avatar"
                                :src="getTransformationUrl(recipe.photo, { width: 42, height: 42, crop: 'fill' })"
                                :alt="recipe.name"/>
                              <router-link
                                :to="`/recipes/${recipe.id}`"
                                target="_blank">
                                {{recipe.name}}
                              </router-link>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            <div
              v-if="!isNew"
              class="row">
              <div class="col">
                <div data-test="card-theme-info" class="card">
                  <div class="card-header">
                    <h3 class="card-title">Info</h3>
                  </div>
                  <div
                    class="table-responsive"
                    style="max-height: 25rem; overflow-y: scroll;">
                    <table class="table text-nowrap card-table">
                      <tbody>
                        <tr>
                          <td class="text-left">Theme status: {{item.status}}</td>
                        </tr>
                        <tr>
                          <td class="text-left">Created at {{moment.utc(item.created_at).local().format('D MMM YYYY HH:mm:ss')}}</td>
                        </tr>
                        <tr>
                          <td class="text-left">Updated at {{moment.utc(item.updated_at).local().format('D MMM YYYY HH:mm:ss')}}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </dimmer>
</template>

<script>
import edit from '@/mixins/edit';
import {themes} from '@/services';

import ImageInput from '@/components/ImageInput';

export default {
  components: {
    ImageInput,
  },
  mixins: [
    edit,
  ],
  data() {
    return {
      withRelations: [
        'mobileBanner',
        'desktopBanner',
        'desktopLeftDecoration',
        'desktopRightDecoration',
        'recipes',
        'recipes.photo',
      ],
      item: {
        name: '',
        main_color: '',
        secondary_color: '',
        description: '',
        mobile_banner_id: null,
        desktop_banner_id: null,
        desktop_left_decoration_id: null,
        desktop_right_decoration_id: null,
        status: '',
        recipes: [],
      },
    };
  },
  computed: {
    route() {
      return `/themes/${this.item.id}`;
    },
    isNameLimit() {
      return this.item.name.length > 16;
    },
  },
  methods: {
    fetchData(id) {
      return themes.getById(id, {
        params: {
          with: this.withRelations.join(','),
        },
      });
    },
    handleSaveTheme() {
      return this.submit();
    },
    submitData(item) {
      const payload = {
        name: item.name,
        main_color: item.main_color,
        secondary_color: item.secondary_color,
        description: item.description,
        mobile_banner_id: item.mobile_banner?.id ?? null,
        desktop_banner_id: item.desktop_banner?.id ?? null,
        desktop_left_decoration_id: item.desktop_left_decoration?.id ?? null,
        desktop_right_decoration_id: item.desktop_right_decoration?.id ?? null,
      };

      if (item.id) {
        payload.id = item.id;
      }

      return themes.saveOrUpdate(payload, {
        params: {
          with: this.withRelations.join(','),
        },
      });
    },
    completed(success) {
      if (!success) {
        return this.$toasted.error('Error while saving theme, please try again later.');
      }
      return this.$toasted.success('Theme saved successfully');
    },
  },
};

</script>

<style scoped>
.char-limit {
  color: #ED8670;
  animation: blink 400ms linear 3;
  display: flex;
  gap: 4px;
  align-items: center;
  justify-content: flex-end;
}

.char-limit small {
  font-weight: bold;
}

.banner-input {
  background-color: #CED4DA;
  border-radius: 2px;
}

.mobile-banner-input {
  width: 340px;
  height: 130px;
}

.desktop-banner-input {
  width: 672px;
  height: 60px;
}

.desktop-decoration-input {
  width: 200px;
  height: 120px;
}

.empty-recipes {
  color: #ADB4BE;
  font-size: 15px;
}

.list-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px
}

@keyframes blink {
  50% {
    color: #495057;
  }
}
</style>
